import { createRouter, createWebHistory } from 'vue-router'

// Brochure content pages
import AgeCheck from '../views/AgeCheck.vue'
import HomePage from '../views/HomePage.vue'
import AboutPage from '../views/AboutPage.vue'
import LatestUpdates from '../views/latestUpdates'
import CustomsPage from '../views/customsPage'
import ContactPage from '../views/contactPage'
import FAQPage from '../views/faqPage'
import PrivacyPage from '../views/privacyPage'
import TermsPage from '../views/termsPage'
import uscPage from '../views/uscPage'
import ModelDisputes from '../views/modelDisputes'

// admin only pages
import AdminHome from '../admin/adminHome.vue'
import AdminImages from '../admin/allImages.vue'
import AdminImage from '../admin/imageSet.vue'
import AdminLogin from '../admin/adminLogin.vue'
import AdminLogout from '../admin/adminLogout.vue'
import AdminVideo from '../admin/videoUpload.vue'
import AdminVideos from '../admin/allVideos.vue'
import AdminUsers from '../admin/allUsers'
import EditVideo from '../admin/editVideo'
import EditUser from '../admin/editUser'

//Member import pages
import MemberSignup from '@/members/signUp'
import MemberSignin from '@/members/signIn'
import forgotPassword from '@/members/forgotPassword.vue'
import resetPassword from '@/members/resetPassword.vue'

//import checkoutTest from '@/members/checkoutTest'
import signUpApproved from '@/views/signUpApproved.vue'
import MemberHome from '@/members/membersHome'
import MembersVideo from "@/members/membersVideo"

// Store pages
import StoreHome from '@/ecommerce/homePage'
import StoreListing from '@/ecommerce/listingPage'
import StoreCart from '@/ecommerce/shoppingCart'
import StoreOrders from '@/ecommerce/customerOrders'
import StoreCheckout from '@/ecommerce/checkout'

// DMCA Pages
import AddDmcaNotice from '@/admin/adminAddDMCAForm'
import AllDmcaForms from '@/admin/adminAllDMCANotices'
import AllDmcaCompanies from '@/admin/adminAllDMCACompanies'


function guardMyAdminRoute(to, from, next) {
    let hasToken             = JSON.parse(localStorage.getItem('token'))
    let isAdminAuthenticated = false;

    if (localStorage.getItem('isAdmin') == true  &&  hasToken)
        isAdminAuthenticated = true;
    else
        isAdminAuthenticated = false;

    if (isAdminAuthenticated) {
        next();
    } else {
        next('/admin/login'); // go to '/login';
    }
}

function guardMyroute(to, from, next) {
    let loggedIn        = JSON.parse(localStorage.getItem('LoggedUser'))
    let isAuthenticated = false;
    if (localStorage.getItem('LoggedUser') && loggedIn === true)
        isAuthenticated = true;
    else
        isAuthenticated = false;
    if (isAuthenticated) {
        next();
    } else {
        next('/member/signin'); // go to '/login';
    }
}

function dobCheck(to, from, next) {
    let dobCheck = JSON.parse(localStorage.getItem('dobCheck'))
    let dobChecked = false;
    if(localStorage.getItem('dobCheck') && dobCheck === true)
        dobChecked = true
    else
        dobChecked = false
    if(dobChecked) {
        next('/home')
    } else {
        next()
    }
}

const routes = [
/*
  {
    path: '/checkouttest',
    name: 'checkoutTest',
    component: checkoutTest,
  },
*/
  {
    path: '/',
    name: 'ageCheck',
    beforeEnter: dobCheck,
    component: AgeCheck,
    meta: {
          title: 'Borderland Bound - Age Check',
          metaTags: [
              {
                name: 'description',
                content: 'Borderland Bound - Age Check'
              }
            ]
      }
  },
  {
    path: '/home',
    name: 'homePage',
    component: HomePage,
    meta: {
          title: 'Borderland Bound - Home',
          metaTags: [
              {
                name: 'description',
                content: 'Borderland Bound - Welcome to Borderland Bound'
              }
            ]
      }
  },
  {
    path: '/about',
    name: 'aboutPage',
    component: AboutPage,
    meta: {
          title: 'Borderland Bound - About',
          metaTags: [
              {
                name: 'description',
                content: 'Borderland Bound - About Borderland Bound'
              }
            ]
      }
  },
  {
    path: '/latest-updates',
    name: 'latestUpdates',
    component: LatestUpdates,
    meta: {
          title: 'Borderland Bound - Latest Updates',
          metaTags: [
              {
                name: 'description',
                content: 'Borderland Bound - The latest updates in our membership section'
              }
            ]
      }
  },
    {
    path: '/customs',
    name: 'customsPage',
    component: CustomsPage,
      meta: {
          title: 'Borderland Bound - Customs',
          metaTags: [
              {
                name: 'description',
                content: 'Borderland Bound - Order custom videos '
              }
            ]
      }
  },
    {
    path: '/contact',
    name: 'contactPage',
    component: ContactPage,
      meta: {
          title: 'Borderland Bound - Contact',
          metaTags: [
              {
                name: 'description',
                content: 'Borderland Bound - Contact our team '
              }
            ]
      }
  },
  {
    path: '/faq',
    name: 'faqPage',
    component: FAQPage,
      meta: {
          title: 'Borderland Bound - FAQ',
          metaTags: [
              {
                name: 'description',
                content: 'Borderland Bound - FAQ and commenly asked questions '
              }
            ]
      }
  },
  {
    path: '/privacy',
    name: 'privacyPage',
    component: PrivacyPage,
      meta: {
          title: 'Borderland Bound - Privacy',
          metaTags: [
              {
                name: 'description',
                content: 'Borderland Bound - Our Privacy policy '
              }
            ]
      }
  },
  {
    path: '/terms',
    name: 'termsPage',
    component: TermsPage,
      meta: {
          title: 'Borderland Bound - Terms',
          metaTags: [
              {
                name: 'description',
                content: 'Borderland Bound - Our Terms and Conditions '
              }
            ]
      }
  },
  {
    path: '/complaints',
    name: 'modelDisputes',
    component: ModelDisputes,
      meta: {
          title: 'Borderland Bound - Complaints',
          metaTags: [
              {
                name: 'description',
                content: 'Borderland Bound - Complaints '
              }
            ]
      }
  },
    {
    path: '/usc',
    name: 'uscPage',
    component: uscPage,
      meta: {
          title: 'Borderland Bound - USC',
          metaTags: [
              {
                name: 'description',
                content: 'Borderland Bound - Our Terms and Conditions '
              }
            ]
      }
  },
  {
    path: '/admin', 
    redirect: { name: 'adminHome' }, 
    beforeEnter: guardMyAdminRoute
  },
  {
    path: '/admin/login',
    name: 'adminLogin',
    component: AdminLogin
  },
  {
    path: '/admin/logout',
    name: 'adminLogout',
    component: AdminLogout
  },
  {
    path: '/admin/home',
    name: 'adminHome',
    component: AdminHome,
    beforeEnter: guardMyAdminRoute
  },
  {
    path: '/admin/images',
    name: 'adminImages',
    component: AdminImages,
    beforeEnter: guardMyAdminRoute
  },
  {
    path: '/admin/image-set',
    name: 'adminImageSet',
    component: AdminImage,
    beforeEnter: guardMyAdminRoute
  },
  {
    path: '/admin/video-upload',
    name: 'adminVideo',
    component: AdminVideo,
    beforeEnter: guardMyAdminRoute
  },
  {
    path: '/admin/videos',
    name: 'adminVideos',
    component: AdminVideos,
    beforeEnter: guardMyAdminRoute
  },
  {
    path: '/admin/users',
    name: 'allUsers',
    component: AdminUsers,
    beforeEnter: guardMyAdminRoute
  },
  {
    path: '/admin/users/:id/edit',
    name: 'editUser',
    component: EditUser,
    beforeEnter: guardMyAdminRoute
  },
  {
    path: '/admin/video/edit',
    name: 'editVideo',
    component: EditVideo,
    beforeEnter: guardMyAdminRoute
  },
  {
    path: '/admin/dmca/all/companies',
    name: 'allCompanies',
    component: AllDmcaCompanies,
    beforeEnter: guardMyAdminRoute
  },
  {
    path: '/admin/dmca/add/notice',
    name: 'addNotice',
    component: AddDmcaNotice,
    beforeEnter: guardMyAdminRoute
  },
  {
    path: '/admin/dmca/all/notices',
    name: 'allNotices',
    component: AllDmcaForms,
    beforeEnter: guardMyAdminRoute
  },
  {
    path: '/member', 
    redirect: { name: 'memberHome' }, 
    beforeEnter: guardMyroute
  },
  {
    path: '/member/signin',
    name: 'memberSignin',
    component: MemberSignin,
    meta: {
      title: 'Borderland Bound - Sign in',
      metaTags: [
          {
            name: 'description',
            content: 'Borderland Bound - Sign in to access a wealth of content'
          }
        ]
    }
  },
  {
    path: '/member/forgot_password',
    name: 'forgotPassword',
    component: forgotPassword,
    meta: {
        title: 'Borderland Bound - Forgot Password',
        metaTags: [{
            name: 'description',
            content: 'Borderland Bound - Forgot Password'
        }]
    }
  },
  {
    path: '/member/reset_password/:token',
    name: 'resetPassword',
    component: resetPassword,
    meta: {
        title: 'Borderland Bound - Reset Password',
        metaTags: [{
            name: 'description',
            content: 'Borderland Bound - Reset Password'
        }]
    }
  },
  {
    path: '/signup-approved',
    name: 'ccBillApproved',
    component: signUpApproved,
    meta: {
      title: 'Borderland Bound - Sign up approved',
      metaTags: [
          {
            name: 'description',
            content: 'Borderland Bound - Sign up approved'
          },
          {
              name: 'robots',
              content: 'noindex'
          }
        ]
  }
  },
  {
    path: '/member/signup',
    name: 'memberSignup',
    component: MemberSignup,
    meta: {
      title: 'Borderland Bound - Sign up ',
      metaTags: [
          {
            name: 'description',
            content: 'Borderland Bound - Join borderland bound '
          }
        ]
      }
  },
    {
    path: '/member/video',
    name: 'membersVideo',
    component: MembersVideo
  },
    {
    path: '/member/home',
    name: 'memberHome',
    beforeEnter: guardMyroute,
    component: MemberHome,
        meta: {
          title: 'Borderland Bound - Members',
          metaTags: [
              {
                name: 'description',
                content: 'Borderland Bound - Welcome to the members section'
              }
            ],
        }
  },
    {
    path: '/ecommerce/home',
    name: 'ecommerceHome',
    component: StoreHome,
      meta: {
          title: 'Borderland Bound - Store',
          metaTags: [
              {
                name: 'description',
                content: 'Borderland Bound - Welcome to our store'
              }
            ]
      }
  },
    {
    path: '/ecommerce/listing',
    name: 'ecommerceListing',
    component: StoreListing,
      meta: {
          title: 'Borderland Bound - Listing',
          metaTags: [
              {
                name: 'description',
                content: 'Borderland Bound - The product below'
              }
            ]
      }
  },
    {
        path: '/ecommerce/cart',
        name: 'StoreCart',
        component: StoreCart,
        meta: {
            title: 'Borderland Bound - Cart',
            metaTags: [
                {
                    name: 'description',
                    content: 'Borderland Bound - Your shopping cart'
                }
            ]
        }
    },
    {
        path: '/ecommerce/orders',
        name: 'ecommerceOrders',
        component: StoreOrders,
        meta: {
            title: 'Borderland Bound - Your Orders',
            metaTags: [
                {
                    name: 'description',
                    content: 'Borderland Bound - You can download or view your videos here'
                }
            ]
        }
    },
    {
        path: '/ecommerce/checkout',
        name: 'StoreCheckout',
        component: StoreCheckout,
        meta: {
            title: 'Borderland Bound - Checkout',
            metaTags: [
                {
                    name: 'description',
                    content: 'Borderland Bound - Your shopping checkout'
                }
            ]
        }
    },
  { path: '/admin/:catchAll(.*)',  redirect: { name: 'adminHome'  }},
  { path: '/member/:catchAll(.*)', redirect: { name: 'memberHome' }},
  { path: '/:catchAll(.*)',        redirect: { name: 'ageCheck'   }}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    const nearestWithTitle        = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const nearestWithMeta         = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    if(nearestWithTitle) {
      document.title = nearestWithTitle.meta.title;
    } else if(previousNearestWithMeta) {
      document.title = previousNearestWithMeta.meta.title;
    }

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    if(!nearestWithMeta) return next();

    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    .forEach(tag => document.head.appendChild(tag));

    next();
  });


export default router
