<template>
    <ccbill-form-layout>
        <div class="relative px-4 py-2 w-100">
            <!-- store-menu></store-menu -->
            <div class="mx-auto w-100 px-4 pt-2 pb-2">
                <div class="mx-auto" style="width:80%;">
                    <div class="flex justify-between">
                        <div>
                            <h1 class="mb-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Checkout</h1>
                        </div>

                        <div v-if="!order_complete"
                             class="text-base font-medium text-white">
                            <a href="/ecommerce/cart" 
                            class="hover:bg-pink-900 w-full rounded-md border border-transparent bg-pink-700 py-3 px-4 text-base font-medium text-white shadow-sm"
                            >Back</a>
                        </div>  
                    </div>

                    <ul role="list" class="mb-3 w-full divide-y divide-gray-200 border-t border-b border-gray-200">
                        <li v-for="video in shoppingCart" :key="video.id" class="w-full py-2 sm:py-2">
                            <div class="flex justify-between">
                                <div class="text-sm text-gray-400">
                                    {{ video.title }}
                                </div>
                                <div class="ml-4 text-sm text-white">
                                    ${{ parseFloat(video.price).toFixed(2) }}
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div v-if="order_complete"
                        class="relative px-4 py-2 mb-2">
                        <div class="text-base font-medium text-white">
                            Your payment was successful. Click here to See your <a href="/member/home" 
                            class="hover:bg-pink-900 w-full rounded-md border border-transparent bg-pink-700 py-3 px-4 text-base font-medium text-white shadow-sm"
                            >Purchases</a>
                        </div>    
                    </div>    
                </div>

                <!-- div class="relative mt-4 mb-4">
                    <a class="hover:bg-pink-900 rounded-md border border-transparent bg-pink-700 py-3 px-4 text-base font-medium text-white shadow-sm"

                    @click="show_ccbill_form"
                    :disabled="payment_in_progress  ||  order_complete">
                        Pay with ccBill
                    </a>
                </div -->    
                <iframe v-if="payment_in_progress"
                        id="ccbill_iframe"
                        :src="this.shop_form + form_payload"
                        style="position:relative; top:0; left:5%; width:90%; height:500px;" frameborder="0"></iframe>
            </div>
        </div>
    </ccbill-form-layout>
</template>

<script>
import md5            from 'crypto-js/md5';
import axios          from "axios";
import { CheckIcon, ClockIcon, QuestionMarkCircleIcon } from '@heroicons/vue/solid'
import BrochureLayout from "@/layouts/BrochureLayout";
import ccbillFormLayout from "@/layouts/ccbillFormLayout.vue";
//import storeMenu      from "@/ecommerce/storeMenu"
import { getStorage, saveStorage } from "@/saveStorage";

export default {
    components: {
        CheckIcon, ClockIcon, QuestionMarkCircleIcon, 
        // storeMenu,
        // BrochureLayout
        ccbillFormLayout
    },

    data() {
        return {
            api_url:      process.env.VUE_APP_API_URL,
            sub_acc:      process.env.VUE_APP_CCBILL_SUBACCOUNT,
            salt:         process.env.VUE_APP_CCBILL_SALT,
            shop_form:    process.env.VUE_APP_CCBILL_SHOP_FORM,
            token:        null,

            shoppingCart: null,
            total_price:  0,
            username:     '',
            ref:          null,
            media_ids:    [],

            payment_in_progress: false,
            order_complete:      false,
        }
    },

    computed: {
        is_registered() {
            let ir = false;

            if (this.token && this.token != '') {
                ir = true;
            }

            return ir;
        },

        form_payload() {
            /*
                978 - EUR
                036 - AUD
                124 - CAD
                826 - GBP
                392 - JPY
                840 - USD
            */
            let period     = '2';
            let currency   = '840';
            let formDigest = md5(this.total_price + period + currency + this.salt).toString();

            let p = '';
            p += '?username='      + this.username;
            p += '&ref='           + this.ref;
            p += '&clientSubacc='  + this.sub_acc;
            p += '&initialPrice='  + this.total_price;
            p += '&initialPeriod=' + period;
            p += '&currencyCode='  + currency;
            p += '&formDigest='    + formDigest;

            return p;
        },
    },

    created() {
        this.token                    = getStorage('token');
        this.username                 = getStorage('username');
        if (this.username.length < 6) {
            this.username = this.username.padEnd(6, '_');
        }

        this.shoppingCart             = getStorage('cart');
        this.order_status_interval_id = null;
        this.ref                      = md5('random:' + Math.random());

        this.total_price = 0;

        let _self = this;
        if (this.shoppingCart  &&  this.shoppingCart.length > 0) {
            this.shoppingCart.forEach(function (item) {
                _self.total_price = _self.total_price + item.price;
                _self.media_ids.push(item.id);
            });
        }

        this.total_price = parseFloat(this.total_price).toFixed(2);
    },

    mounted() {
        this.show_ccbill_form();
    },

    methods: {
        calcCartTotal() {
            var shopping_cart = getStorage('cart');
            var total_price   = 0;

            if (shopping_cart  &&  shopping_cart.length > 0) {
                shopping_cart.forEach(function (item) {
                    total_price = parseFloat(total_price) + parseFloat(item.price);
                });
            }

            total_price = parseFloat(total_price).toFixed(2);

            return total_price;
        },

        show_ccbill_form() {
            var total_price = this.calcCartTotal();
            if (parseFloat(total_price) > 100) {
                this.$swal({
                    title:              '',
                    html:               'Your shopping cart total cannot be over $100.',
                    confirmButtonColor: '#be185d',
                    confirmButtonText:  'Ok',
                    icon:               'success',
                    background:         '#3f3f46',
                });
            }
            else {
                this.payment_in_progress = true;
            
                // place order with API
                const token = getStorage('token');

                const headers = {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + token
                }


                let formData = new FormData();
                formData.append("price", this.total_price);
                formData.append("media_ids", JSON.stringify(this.media_ids));
                formData.append("ref", this.ref);

                axios.post(this.api_url + '/api/order', formData, {headers: headers}).then((res) => {
                    //console.log('store purchase result = ', res)

                    // Start polling order status
                    this.order_status_interval_id = setInterval(this.check_status, 1000);

                    this.payment_in_progress = true;
                }).catch(error => {
                    console.log('order error', error);
                });
            }
        },

        check_status() {
            // check order status
            const headers = {
                "Authorization": "Bearer " + this.token
            }

            axios.get(this.api_url + '/api/status/' + this.ref, {headers: headers}).then((res) => {
                //console.log('order status result = ', res);

                if (res.data && res.data.is_paid == true) {
                    //console.log('order status checking complete');
                    clearInterval(this.order_status_interval_id);
                    saveStorage('cart', [])
                    this.order_complete = true;
                }
            }).catch(error => {
                console.log('order status checking stopped due to error', error);
                clearInterval(this.order_status_interval_id);
            });
        }
    },
}
</script>