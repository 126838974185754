<template>
    <router-view/>
</template>

<script>
export default {
    created() {
        if (process.env.VUE_APP_ENV == 'production') {
            document.addEventListener('contextmenu', event => event.preventDefault());
        }
    },
}
</script>

<style>
</style>
