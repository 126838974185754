<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div"  class="relative z-10" @close="closeMe(); open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto" >
        <div  ref="vplr"  class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative bg-zinc-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-4xl sm:w-full sm:p-6">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-white">{{videoTitle}}</DialogTitle>
                  <div class="mt-2">
                    <div class="player-container">
                      <vue3-video-player @play="your_method" :src="videoLink"></vue3-video-player>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:grid-flow-row-dense font-medium text-white hover:cursor-pointer"
                   v-if="!showFullText"
                   @click="toggleFullText"
                   v-html="videoDesc.slice(0,400) + (videoDesc.length > 400 ? ' ...<br><br>(Click description for more...)' : '')">
              </div>
              <div class="mt-5 sm:grid-flow-row-dense font-medium text-white" 
                   v-if="showFullText"
                   v-html="videoDesc">

              </div>
              <div class="mt-5 sm:grid-flow-row-dense">
                <a :href="this.downloadLink" 
                   type="button" 
                   class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-700 text-base font-medium text-white hover:bg-pink-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-900 sm:col-start-2 sm:text-sm mb-3" >Download</a>
                <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-700 text-base font-medium text-white hover:bg-pink-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-900 sm:col-start-2 sm:text-sm" @click="closeMe(); open = false">Close</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/outline'

export default {
    components: {
        Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, CheckIcon
    },
    props: [
        'open', 'videoLink', 'downloadLink', 'videoTitle', 'videoDesc'
    ],

    data () {
        return{
            showFullText: false,
        }
    },

    mounted() {
    },

    methods: {
        closeMe() {
            this.showFullText = false;
        },
        toggleFullText(){
            this.showFullText = true;
        }
    }
}

</script>